import React, { useCallback, useMemo, useState } from 'react';
import { Button, Col, Divider, Form, Icon, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const LANGUAGES_OPTIONS = LANGUAGES => Object.keys(LANGUAGES).map(lang => (
  <Select.Option value={lang} key={lang}>
    {LANGUAGES[lang]}
  </Select.Option>
));

function AddField({ form, onSubmit, languages }) {
  const { getFieldDecorator } = form;
  const { i18n, t } = useTranslation('common');

  console.log(i18n.language);
  const defaultLang = useMemo(() => {
    return Object.keys(languages).includes(i18n.language) ? i18n.language : 'en';
  }, []);

  const handleSubmit = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    form.validateFields((err, values) => {
      if (!err) {
        onSubmit(values, () => form.resetFields());
      }
    });
  });

  return (
    <Form layout="inline" onSubmit={handleSubmit}>
      <Form.Item label={t('language')}>
        {getFieldDecorator('language', { initialValue: defaultLang, rules: [{ required: true }] })(
          <Select style={{ minWidth: '6rem' }} showSearch>
            {LANGUAGES_OPTIONS(languages)}
          </Select>,
        )}
      </Form.Item>
      <Form.Item label={t('label')}>
        {getFieldDecorator('label', { rules: [{ required: true }] })(<Input />)}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit">{t('add')}</Button>
      </Form.Item>
    </Form>
  );
}

const AddFieldForm = Form.create({ name: 'addLabelField' })(AddField);

const L10nField = React.forwardRef(({ value, onChange, languages: LANGUAGES }, ref) => {
  const initial = value || {};
  const [state, setState] = useState(initial);

  const changeHandler = useCallback(
    (val, checkHasKeys = false) => {
      // check that isn't an empty object only on delete action
      let hasKeys = true;
      if (checkHasKeys) {
        hasKeys = Object.keys(val).length > 0;
      }

      onChange(hasKeys ? val : null);
      setState(val);
    },
    [setState, onChange],
  );

  const handleAdd = useCallback(
    data => {
      changeHandler({
        ...state,
        [data.language]: data.label,
      });
    },
    [state, changeHandler],
  );

  const handleChange = useCallback(
    (key, val) => {
      changeHandler({
        ...state,
        [key]: val,
      });
    },
    [state, changeHandler],
  );

  const removeKey = useCallback(
    key => {
      const copy = { ...state };
      delete copy[key];
      changeHandler(copy, true);
    },
    [state, changeHandler],
  );

  const keys = Object.keys(state);

  return (
    <React.Fragment>
      {keys.map(lang => (
        <Row gutter={4}>
          <Col span={6}>{LANGUAGES[lang]}</Col>
          <Col span={14}>
            <Input
              value={state[lang]}
              onChange={e => {
                e.stopPropagation();
                e.preventDefault();
                handleChange(lang, e.target.value);
              }}
            />
          </Col>
          <Col span={4}>
            <Button type="danger" onClick={() => removeKey(lang)}>
              <Icon type="delete" />
            </Button>
          </Col>
        </Row>
      ))}
      {keys.length > 0 && <Divider type="horizontal" />}
      <AddFieldForm onSubmit={handleAdd} ref={ref} languages={LANGUAGES} />
    </React.Fragment>
  );
});

export default L10nField;
