import React, { useState } from 'react';
import { Button, Modal, notification, Upload, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import useWorkerFunction from '../hooks/useWorkerFunction';

export default function ImportContent({ id, useModal = false }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('materials');

  const upload = useWorkerFunction({
    name: 'actions/contentImport',
    preBody: {
      materialDocumentId: id,
    },
    fileField: 'archive',
  })

  const content = (
    <Upload.Dragger
      multiple={false}
      listType="picture"
      customRequest={upload}
      showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
    >
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">{t('drag-drop-content')}</p>
    </Upload.Dragger>
  )

  return useModal ? (
    <React.Fragment>
      <Modal visible={open} title={t('import')} footer={null} onCancel={() => setOpen(false)}>
        {content}
      </Modal>
      <Button onClick={() => setOpen(true)}>{t('import-content')}</Button>
    </React.Fragment>
  ) : content;
}
