import { useCallback, useState } from 'react';

function useToggle() {
  const [visible, setVisible] = useState(false);
  const toggle = useCallback(() => setVisible(!visible), [visible]);

  return [visible, toggle];
}

export default useToggle;
