import firebase from 'firebase';

export default async function callHttpsFunction(base, endpoint, data) {
  const options = {
    method: 'GET',
  };

  const headers = new Headers();
  const token = await firebase.auth().currentUser.getIdToken();
  headers.append('Authorization', `Bearer ${token}`);

  if (data) {
    options.method = 'POST';
    options.body = JSON.stringify({ data });
    headers.append('Content-Type', 'application/json');
  }

  options.headers = headers;

  const res = await fetch(`${base}/${endpoint}`, options);

  if (res.status >= 400) {
    const errorResponse = await res.json();
    throw Error(errorResponse.error.message);
  }
  return res.json();
}
