import { useCallback } from 'react';
import { notification } from 'antd';
import useFirestore from './useFirestore';

export default function useSetSystemOnline() {
  const firestore = useFirestore();

  return useCallback(async () => {
    try {
      await firestore()
        .collection('system')
        .doc('status')
        .update({
          online: true,
        });
      notification.success({ message: 'System is back online' });
    } catch (e) {
      console.log(e);
      notification.error({ message: 'Error' });
    }
  });
}
