import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { filterStartsWith } from '../utils';


function addData(ref, PAGE_LIMIT, field) {
  return async (setState, state, reset = false) => {
    setState({ ...state, loading: true });
    const prevCursors = reset ? [] : [...state.prevCursors];
    if (state.search && field) {
      ref = filterStartsWith(ref, state.search, field);
    }
    if (!reset && state.data.length > 0) {
      const lastRef = state.data[state.data.length - 1].__doc;
      ref = ref.startAfter(lastRef);
      prevCursors.push(lastRef);
    }
    ref = ref.limit(PAGE_LIMIT);
    const qs = await ref.get();
    const res = qs.docs.map(d => ({ __id: d.id, __ref: d.ref, ...d.data(), __doc: d }));
    setState({
      prevCursors,
      data: res,
      loading: false,
      search: state.search,
      hasMore: res.length === PAGE_LIMIT,
    });
  }
}

function loadPrev(ref, PAGE_LIMIT, field) {
  return async (setState, state) => {
    setState({ ...state, loading: true });
    const prevCursors = [...state.prevCursors];
    prevCursors.pop();
    if (state.search && field) {
      ref = filterStartsWith(ref, state.search, field);
    }
    if (prevCursors.length > 0) {
      const lastRef = prevCursors[prevCursors.length - 1];
      ref = ref.startAfter(lastRef);
    }
    ref = ref.limit(PAGE_LIMIT);
    const qs = await ref.get();
    const res = qs.docs.map(d => ({ __id: d.id, __ref: d.ref, ...d.data(), __doc: d }));

    setState({
      prevCursors,
      data: res,
      loading: false,
      search: state.search,
      hasMore: res.length === PAGE_LIMIT,
    });
  }
}


export function usePaginatedQuery({ t, collectionReference, pageSize, field }) {
  const [state, setState] = useState({
    prevCursors: [],
    data: [],
    loading: true,
    hasMore: true,
    search: '',
  });

  const next = useMemo(() => addData(collectionReference, pageSize, field),
    [collectionReference, pageSize, field]);
  const prev = useMemo(() => loadPrev(collectionReference, pageSize, field),
    [collectionReference, pageSize, field]);

  useEffect(() => {
    next(setState, state, true);
  }, [next, state.search]);

  const pagination = useMemo(() => {
    return (
      <Row type="flex" justify="space-between" style={{ margin: '1.5rem 0' }}>
        <Col md={4}>
          <Button
            disabled={state.loading || state.prevCursors.length === 0}
            onClick={() => prev(setState, state)}
          >
            {t('prev')}
          </Button>
        </Col>
        <Col md={4} style={{ textAlign: 'right' }}>
          <Button
            disabled={!state.hasMore || state.loading}
            onClick={() => next(setState, state)}
          >
            {t('next')}
          </Button>
        </Col>
      </Row>
    );
  }, [state, t]);

  const onSearch = useCallback(search => setState({ ...state, search }), [state, setState]);

  return {
    pagination,
    onSearch,
    ...state,
  }
}
