import { useCallback } from 'react';
import { notification } from 'antd';
import useFirestore from './useFirestore';

export default function useSetMaintenanceMessage(onSuccess) {
  const firestore = useFirestore();

  return useCallback(async ({ maintenanceReason }, clean) => {
    try {
      await firestore()
        .collection('system')
        .doc('status')
        .update({
          maintenanceReason,
          online: false,
        });
      if (onSuccess) {
        onSuccess(clean);
      } else {
        notification.success({ message: 'Maintenance message updated' });
      }
    } catch (e) {
      console.log(e);
      notification.error({ message: 'Error' });
    }
  });
}
