import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';


export default function useGetDefaultLanguage(LANG) {
  const { i18n } = useTranslation();

  return useMemo(() => {
    const LANG_KEYS = Object.keys(LANG);
    if (LANG_KEYS.includes(i18n.language)) {
      // The current language is in the form xx-XX and is inside the LANG map
      return i18n.language;
    }
    if (LANG_KEYS.map(l => l.split('-')[0]).includes(i18n.language)) {
      // The current language is not present as it is in the language map, but there is a similar
      // language code with the same prefix `xx`
      const langIndex = LANG_KEYS.map(l => l.split('-')[0]).indexOf(i18n.language);
      return LANG_KEYS[langIndex];
    }
    return 'en-US';
  }, [i18n]);
}
