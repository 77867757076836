import { useEffect } from 'react';
import useFirestore from './useFirestore';
import useDataLoadingState, { LOAD_END_ACTION_HOOK } from './useDataLoadingState';

export default function useDocument(documentName, collectionName, basePath) {
  const [store, dispatch] = useDataLoadingState();
  const firestore = useFirestore();

  const base = basePath || firestore().collection(collectionName);

  useEffect(
    () =>
      base.doc(documentName).onSnapshot(d => {
        dispatch({
          type: LOAD_END_ACTION_HOOK,
          data: { __id: d.id, ...d.data() },
        });
      }),
    [],
  );
  return store;
}
