import React, { useState } from 'react';
import { Button, Modal, notification, Upload, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import useWorkerFunction from '../hooks/useWorkerFunction';

export default function ImportMaterial() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation('materials');

  const upload = useWorkerFunction({
    name: 'actions/materialImport',
    fileField: 'archive',
  })

  return (
    <React.Fragment>
      <Modal visible={open} title={t('import')} footer={null} onCancel={() => setOpen(false)}>
        <Upload.Dragger
          multiple={false}
          listType="picture"
          customRequest={upload}
          showUploadList={{ showDownloadIcon: false, showRemoveIcon: false }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">{t('drag-drop-material')}</p>
        </Upload.Dragger>
      </Modal>
      <Button onClick={() => setOpen(true)}>{t('import')}</Button>
    </React.Fragment>
  );
}
