import React from 'react';
import { Select } from 'antd';
import keys from 'lodash/keys';

function ChoicesSelect({ choices, ...props }, ref) {
  let options = null;
  if (Array.isArray(choices)) {
    options = choices.map((v, i) => (
      <Select.Option key={v} value={i}>
        {v}
      </Select.Option>
    ));
  } else {
    options = keys(choices).map(k => (
      <Select.Option key={k} value={k}>
        {choices[k]}
      </Select.Option>
    ));
  }
  return (
    <Select {...props} ref={ref}>
      {options}
    </Select>
  );
}

export default React.forwardRef(ChoicesSelect);
