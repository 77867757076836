import React, { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import useFirebase from '../hooks/useFirebase';

export default function FirestoreDownload({ src, ...props }) {
  const [ready, setSrc] = useState(false);
  const firebase = useFirebase();

  useEffect(() => {
    async function handleLoading() {
      try {
        const storage = firebase.storage();
        const gsReference = storage.refFromURL(src);
        const url = await gsReference.getDownloadURL();
        setSrc(url);
      } catch (e) {
        notification.error({ message: e.message });
        console.log(e);
        setSrc(null);
      }
    }

    setSrc(false);
    if (src && src.startsWith('http')) {
      setSrc(src);
    } else if (src && src.startsWith('gs')) {
      handleLoading();
    } else {
      setSrc(null);
    }
  }, [firebase, src]);

  // eslint-disable-next-line jsx-a11y/alt-text
  return <Button {...props} target="_blank" href={ready} download loading={!ready} />;
}
