
export function filterStartsWith(ref, strSearch, field) {
  const strlength = strSearch.length;
  const strFrontCode = strSearch.slice(0, strlength - 1);
  const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
  const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);
  if (ref) {
    return ref.where(field, '>=', strSearch).where(field, '<', endcode);
  } else {
    return [
      { field, op: '>=', value: strSearch },
      { field, op: '<', value: endcode },
    ]
  }
}
