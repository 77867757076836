import React, { useState } from 'react';
import { Button, Modal, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import useDocument from '../hooks/useDocument';
import FirestoreDownload from './FirestoreDownload';
import SpinnerBlock from './SpinnerBlock';
import useFunction from '../hooks/useFunction';

function MaterialExport({ basePath, id }) {
  const { t } = useTranslation('materials');
  const { data, loading } = useDocument(id, null, basePath.collection('materialExports'));

  return loading || !data || data.status !== 'completed' ? (
    <React.Fragment>
      <p>{t('export-loading')}</p>
      <SpinnerBlock />
    </React.Fragment>
  ) : (
    <div style={{ marginTop: '2rem', textAlign: 'center' }}>
      <FirestoreDownload src={data.archiveUri} size="large" type="primary">
        {t('download')}
      </FirestoreDownload>
    </div>
  );
}

export default function ExportMaterial({ id, path }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exporting, setExportId] = useState(null);
  const { t } = useTranslation('materials');

  const startProcessing = useFunction({
    name: 'materialExportRequest',
    preBody: {
      materialId: id,
    },
    onSuccess: ({ response }) => {
      console.log(response);
      setExportId(response.result.exportId);
      setLoading(false);
    },
    onError: e =>
      notification.error({
        message: t('export-error'),
        description: e.message,
      }),
  });

  return (
    <React.Fragment>
      <Modal visible={open} title={t('export')} footer={null} onCancel={() => setOpen(false)}>
        {!exporting && (
          <React.Fragment>
            <p>{t('export-description')}</p>
            <div style={{ marginTop: '2rem', textAlign: 'center' }}>
              <Button
                type="primary"
                size="large"
                loading={loading}
                disabled={loading}
                onClick={() => {
                  setLoading(true);
                  startProcessing();
                }}
              >
                {t('start-export')}
              </Button>
            </div>
          </React.Fragment>
        )}
        {exporting && <MaterialExport id={exporting} basePath={path} />}
      </Modal>
      <Button onClick={() => setOpen(true)}>{t('export')}</Button>
    </React.Fragment>
  );
}
