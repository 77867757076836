import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import SpinnerPage from './SpinnerPage';
import { useIsAuthing } from '../hooks';

function AuthedRoute(props) {
  const [authed, loading] = useIsAuthing();

  if (loading) {
    return <SpinnerPage />;
  }

  if (authed) {
    return <Route {...props} />;
  }

  return <Route {...props} component={() => <Redirect to="/login" />} />;
}

export default AuthedRoute;
