import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useFirebase from './useFirebase';
import { notification } from 'antd';

export default function useWorkerFunction({ name, method = 'POST', fileField = 'upload', preBody = {} }) {
  const firebase = useFirebase();
  const t = useTranslation('errors');

  return useCallback(async ({ onSuccess, onError, file }) => {
    const token = await firebase.auth().currentUser.getIdToken(true);

    const data = new FormData();
    data.append(fileField, file);
    for (const field in preBody) {
      data.append(field, preBody[field]);
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_WORKER_FUNCTIONS_BASE}/${name}`, {
        body: data,
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      });

      if (res.status === 500) {
        throw Error(t('error-500'));
      }

      if (res.status === 204) {
        if (onSuccess) {
          onSuccess(null);
        }
        return null;
      }

      const response = await res.json();
      if (res.status > 400) {
        throw Error(response.error.message);
      }
      onSuccess(response);
    } catch (e) {
      notification.error({ message: e.message });
      onError(e);
    }
  });
}
