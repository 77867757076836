import { useCallback } from 'react';

export default function useHandleSubmit({ form, handler }) {
  return useCallback((e, ...props) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        handler(values, form.resetFields, ...props);
      }
    });
  });
}
