import React, { useCallback, useState } from 'react';
import find from 'lodash/find';
import { Button, Col, Icon, List, Row, Select, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import useCollectionWhere from '../hooks/useCollectionWhere';

const WHERE = {
  field: 'publishedAt',
  op: '!=',
  value: null,
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 4px 0`,
  ...draggableStyle,
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function LinkedMaterialsField({ value = [], onChange: dispatchChange }, ref) {
  const { data: materials = [], loading } = useCollectionWhere('materials', null, WHERE);
  const [selected, setSelected] = useState(null);
  const [linked, setLinked] = useState(value);

  const onChange = useCallback(
    state => {
      // const collection = firestore().collection('materials');

      dispatchChange(state);
      setLinked(state);
    },
    [dispatchChange],
  );

  const unlink = useCallback(id => onChange(linked.filter(l => l !== id)), [linked, onChange]);

  const { t } = useTranslation('common');

  const onDragChange = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const items = reorder(linked, result.source.index, result.destination.index);

      onChange(items);
    },
    [linked, onChange],
  );

  if (loading) {
    return <Spin />;
  }

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={20}>
          <Select onChange={setSelected} value={selected} ref={ref}>
            {materials
              .filter(m => !linked.includes(m.__id))
              .map(material => (
                <Select.Option key={material.__id}>{material.title}</Select.Option>
              ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button
            disabled={!selected}
            onClick={() => {
              onChange(Array.from(new Set([...linked, selected])));
              setSelected(null);
            }}
          >
            {t('add')}
          </Button>
        </Col>
      </Row>
      <DragDropContext onDragEnd={onDragChange}>
        <Droppable droppableId="droppable">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {linked
                .map(m => find(materials, mat => mat.__id === m))
                .map((item, index) => (
                  <Draggable key={item.__id} draggableId={item.__id} index={index}>
                    {(providedInner, snapshotInner) => (
                      <div
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}
                        style={getItemStyle(
                          snapshotInner.isDragging,
                          providedInner.draggableProps.style,
                        )}
                      >
                        <List.Item
                          extra={[
                            <Button key="unlink" onClick={() => unlink(item.__id)}>
                              <Icon type="disconnect" />
                            </Button>,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <React.Fragment>
                                <Icon type="read" /> {item.title}
                              </React.Fragment>
                            }
                          />
                        </List.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </React.Fragment>
  );
}

export default React.forwardRef(LinkedMaterialsField);
