import { useCallback } from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import useAuth from './useAuth';

export default function useLogin(form) {
  const auth = useAuth();
  const history = useHistory();

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      form.validateFields(async (err, { email, password }) => {
        if (!err) {
          try {
            await auth().signInWithEmailAndPassword(email, password);
            history.replace('/');
          } catch (error) {
            notification.error({
              message: 'Errore',
              description: error.message,
            });
          }
        }
      });
    },
    [form, auth, history],
  );

  return handleSubmit;
}
