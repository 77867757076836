import { Form } from 'antd';
import React from 'react';
import useHandleSubmit from '../hooks/useHandleSubmit';

const formLayout = {
  layout: 'horizontal',
  labelCol: { span: 3 },
  wrapperCol: { span: 10 },
};

function FieldForm({ form, children, onSubmit, onSuccess, layout = {} }) {
  const { getFieldDecorator } = form;
  const handleSubmit = useHandleSubmit({ form, handler: onSubmit });
  return (
    <Form
      {...formLayout}
      {...layout}
      style={{ marginTop: '1.5rem' }}
      onSubmit={e => handleSubmit(e, onSuccess)}
    >
      {children(getFieldDecorator)}
    </Form>
  );
}

export const FieldFormWrapper = Form.create()(FieldForm);
