import React, { useMemo, useState } from 'react';
import { Select } from 'antd';
import useCollectionWhere from '../hooks/useCollectionWhere';

const MIN_WIDTH = { minWidth: '10rem' };

export default function RemoteSelect({
  collection,
  field,
  onChange,
  value,
  extraWhere = null,
  mode = 'multiple',
}) {
  const [text, setText] = useState({
    field,
    op: '>=',
    value: '',
  });

  const where = useMemo(() => {
    if (extraWhere) {
      const wh = Array.isArray(text) ? text : [text];
      return [...extraWhere, ...wh];
    }
    return text;
  }, [text, extraWhere]);

  const { data = [] } = useCollectionWhere(collection, null, where);

  const extra = useMemo(() => (value ? { value } : {}), [value]);

  return (
    <Select
      showSearch
      mode={mode}
      {...extra}
      defaultActiveFirstOption={false}
      style={MIN_WIDTH}
      showArrow={false}
      filterOption={false}
      onChange={onChange}
      onSearch={strSearch => {
        if (!strSearch) {
          setText([]);
        } else {
          const strlength = strSearch.length;
          const strFrontCode = strSearch.slice(0, strlength - 1);
          const strEndCode = strSearch.slice(strlength - 1, strSearch.length);
          const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

          setText([
            {
              field,
              op: '>=',
              value: strSearch,
            },
            {
              field,
              op: '<',
              value: endcode,
            },
          ]);
        }
      }}
    >
      {data.map(d => (
        <Select.Option key={`${d.__id}`}>{d[field]}</Select.Option>
      ))}
    </Select>
  );
}
