import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from './useAuth';

export default function useLogout() {
  const history = useHistory();
  const auth = useAuth();

  const logout = useCallback(async () => {
    history.replace('/login');
    auth().signOut();
  }, [history]);

  return logout;
}
