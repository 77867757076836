import { useEffect } from 'react';
import useFirestore from './useFirestore';
import useDataLoadingState, { LOAD_END_ACTION_HOOK } from './useDataLoadingState';

export default function useCollection(collectionName, basePath) {
  const [store, dispatch] = useDataLoadingState();
  const firestore = useFirestore();

  const base = basePath || firestore();

  useEffect(
    () =>
      base.collection(collectionName).onSnapshot(qs => {
        dispatch({
          type: LOAD_END_ACTION_HOOK,
          data: qs.docs.map(d => ({ __id: d.id, ...d.data() })),
        });
      }),
    [],
  );
  return store;
}
