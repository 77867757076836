import { Button, Form, Modal, notification } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import RemoteSelect from './RemoteSelect';
import useHandleSubmit from '../hooks/useHandleSubmit';
import useFunction from '../hooks/useFunction';
import useToggle from '../hooks/useToggle';

function OwnerForm({ form, onSubmit, loading }) {
  const { getFieldDecorator } = form;
  const { t } = useTranslation('users');
  const handleSubmit = useHandleSubmit({ form, handler: onSubmit, noReset: true, returnFirst: true });
  return (
    <Form onSubmit={handleSubmit}>
      <Form.Item label={t('userId')}>
        {getFieldDecorator('newOwnerId', {
          rules: [
            {
              required: true,
            },
          ],
        })(<RemoteSelect collection="users" field="nickname" mode="single" />)}
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" loading={loading}>
          {t('edit')}
        </Button>
      </Form.Item>
    </Form>
  );
}

const ChangeOwnerForm = Form.create()(OwnerForm);

export default function ChangeOwner({ projectRef }) {
  const [state, toggle] = useToggle();
  const { t } = useTranslation('users');

  const changeOwner = useFunction({
    name: 'changeProjectOwner',
    preBody: { projectId: projectRef.id },
    onSuccess: toggle,
    onError: e => {
      notification.error({ message: e.message });
    },
  })

  const changeOwnerFn = useMemo(() => {
    return async ([ value ]) => changeOwner(value);
  }, [changeOwner])

  const { run, isPending } = useAsync({
    deferFn: changeOwnerFn,
  });

  return (
    <React.Fragment>
      <Button onClick={toggle}>{t('change-owner')}</Button>
      <Modal visible={state} closable onCancel={toggle} footer={null} title={t('change-owner')}>
        <ChangeOwnerForm onSubmit={run} loading={isPending} />
      </Modal>
    </React.Fragment>
  );
}
