import { useEffect, useState } from 'react';
import useFirestore from './useFirestore';

export default function useSystemStatus() {
  const [status, setData] = useState({ data: null, isLoading: true });
  const firestore = useFirestore();
  useEffect(
    () =>
      firestore()
        .collection('system')
        .doc('status')
        .onSnapshot(value =>
          setData({
            isLoading: false,
            data: value.data(),
          }),
        ),
    [],
  );

  return status;
}
