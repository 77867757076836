import * as React from 'react';
import firebase from 'firebase';
import { FirebaseContext } from '../contexts';

export default function FirebaseProvider({ config, ...props }) {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  return <FirebaseContext.Provider value={config} {...props} />;
}
